import React from 'react';


function Header() {
  return (
    <header className="bg-white shadow-md py-4 sm:py-6">
     
      <h3 className="text-center text-xl sm:text-2xl font-semibold mt-2 sm:mt-4">
        WC Pune 2024, Photos By Lokesh
      </h3>
    </header>
  );
}

export default Header;
